.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 8.5vw;
  grid-gap: 2.5vw;
}
.image-card{
  height: 825px;
  overflow-y: auto;
}
.griditem {
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
.griditem:hover {
  cursor: pointer;
}

.image1 {
  background-position: left center;
}

.vertical_line{
  border-left: 1px solid #0000001a;
  height: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
  max-height: calc(100vh - 320px) !important;
}