.reportTable {
  min-width: 700px;
}
.rbc-btn-group{
  display: none !important;
}
.rbc-calendar {
  margin-top: 30px !important;
}
.rbc-month-view{
  margin-top: 20px !important;
}
//In Attendance feature configure tab 2 button css
@media (max-width: 376px) {
  .b1{
    margin-bottom: 10px;
    margin-right: 52px;
  }
}

@media (max-width: 376px) {
  .b2{
    margin-right: 46px;
    margin-top: 10px;
  }
}

//In Subscription feature searchbar and button css
@media (max-width: 474px) {
  .searchText{
    width: 100%;
  }
  .searchTextbtn{
    margin-right: -15px;
    margin-top: 20px;
  }
}
@media (min-width: 475px) {
  .searchText{
    width: 40%;
  }
}
