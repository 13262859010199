@media (max-width: 2500px) {
  .image-item {
    margin-right: 10px;
    height: 100%;
  }
}
@media (max-width: 1550px) {
  .new-card-css {
    max-width: 294px !important;
  }
}
