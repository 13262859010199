.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
}

.tags {
  background: #fff;
  padding: 5px;
  overflow: hidden;
}

.tag {
  color: #fff;
  font-weight: bold;
  background: #147b82;
  float: left;
  padding: 14px 22px;
  border-radius: 10em;
  margin: 5px;
  border: 1px solid transparent;
}
.tag:hover{
  background: #5DBFC4;
  vertical-align: top;
  //padding: 18px 17px 16px;
  padding: 14px 22px;

}
.tag:focus{
  background: #5DBFC4;
  vertical-align: top;
  //padding: 18px 17px 16px;
  padding: 14px 22px;

}
.tag2{
  color: #fff;
  font-weight: bold;
  background: #5DBFC4;
  border-radius: 10em;
  float: left;
  margin: 5px;
  vertical-align: top;
  //padding: 18px 17px 16px;
  padding: 14px 22px;
  border: 1px solid transparent;
}


