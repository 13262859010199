@media (min-width: 1200px) {
  .vertical {
    border-right: 1px solid #c7c9c9;
  }
}
@media (min-width: 1200px) {
  .vertical2 {
    border-right: 1px solid #c7c9c9;
    height: 100px;
    width: auto;
    margin-left: 15px;

    .orclass {
      position: absolute;
      right: -2px;
      top: 126px;
      background: white;
    }
  }
}
@media (min-width : 1200px) and (max-width: 1280px) {
  .upload-btn {
    float:left !important;
    margin-top: -7px !important;
    margin-left: 71px !important;
    position: absolute;
  }
}
@media (min-width: 1281px) {
  .upload-btn {
    float:left !important;
    top: 46% !important;
    left: 35% !important;
    position: absolute;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .subscribe-btn {
    float:left !important;
    margin-left: 54px !important;
    margin-top: 31px !important;
    position: absolute;
  }
}
@media (min-width: 1281px) {
  .subscribe-btn {
    float:left !important;
    top: 46% !important;
    left: 35% !important;
    position: absolute;
  }
}
@media (min-width : 1200px) and (max-width : 1280px) {
  .orclass {
    position: absolute;
    right: -2px;
    top: 126px;
  }
}
@media (min-width: 1281px) {
  .orclass {
    position: absolute;
    right: -2px;
    top: 132px;
  }
}
@media (max-width: 1200px) {
  .orclass {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .note {
    font-size : 8px
  }
}
@media (min-width: 1281px) {
  .note{
    font-size : 8px
  }
}
@media (max-width: 1200px) {
  .add-camera {
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .add-camera {
    text-align: right;
  }
}