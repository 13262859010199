.violation-card-scroll {
  overflow-y: auto;
}
.violation-card-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0, 0, 0.3);
  background-color: #f5f5f5;
}

.violation-card-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.violation-card-scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 2px solid #ffffff;
}

.violation-card {
  min-height: 50px;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start !important;
  word-break: break-word;
}
@media (max-width: 1024px) {
  .fix-box-ipad {
    max-width: 100%;
    flex: 0 0 100%;
    min-height: auto !important;
  }
}

@media (min-width: 1200px) {
  .set-violation-card-screen {
  }
}

.scroll-page-class {
  width: 80% !important;
}
@media (min-width: 1500px){
  .violation-card-heights{
    min-height: 580px;
    max-height: 547px;
  }
  .height-of-main-col{
    min-height: 634px;
  }
  .scroll-section-height{
    height: 78%
  }
}
@media (min-width: 1500px) and (min-height: 700px) and (max-height: 900px){
  .violation-card-heights{
    min-height: 380px;
    max-height: 400px;
  }
  .height-of-main-col{
    min-height: 300px;
  }
  .scroll-section-height{
    height: 78%
  }
}

@media (min-width: 1400px) and (max-width: 1500px){
  .violation-card-heights{
    min-height: 504px;
    max-height: 420px;
  }
  .height-of-main-col{
    min-height: 536px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px){
  .violation-card-heights{
    min-height: 235px;
    max-height: 431px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 800px){
  .violation-card-heights{
    min-height: 235px;
    max-height: 386px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) and (min-height: 800px) and (max-height: 900px){
  .violation-card-heights{
    min-height: 480px;
    max-height: 241px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) and (min-height: 900px) and (max-height: 960px){
  .violation-card-heights{
    min-height: 590px;
    max-height: 241px;
  }
}
@media (min-width: 1100px) and (max-width: 1200px){
  .violation-card-heights{
    min-height: 405px;
    max-height: 241px;
  }
}
@media (min-width: 1026px) and (max-width: 1100px){
  .violation-card-heights{
    min-height: 405px;
    max-height: 241px;
  }
}
.violation-card-body{
  overflow-x: hidden;
}

.violation-card-parent{
  overflow: auto;
}
.custom-pagination{
  margin-top: 54px !important;
}
