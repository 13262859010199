.notification-counter {
  position: absolute;
  color: white;
  border-radius: 50%;
  background: red;
  height: 15px;
  width: 15px;
  font-size: 10px;
  font-weight: 900;
  right: 5px;
}