@media(max-width: 1199px){
  .my-image{
    height: 75px !important;
    width: 75px !important;
    margin-right : 10px !important;
    margin-top:30px !important;
    border: 5px solid #147b82;
  }
  .my-images{
    height: 75px !important;
    width: 75px !important;
    margin-right : 10px !important;
    margin-top:30px !important;
  }
  .main-card{
    justify-content: space-evenly;
    margin-top: 30%;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {

  .my-image{
    height: 75px !important;
    width: 75px !important;
    margin-right : 10px !important;
    margin-top:30px !important;
    border: 5px solid #147b82;
  }
  .my-images{
    height: 75px !important;
    width: 75px !important;
    margin-right : 10px !important;
    margin-top:30px !important;
  }
  .main-card{
    justify-content: space-evenly;
    margin-top: 30%;
  }
}
@media (min-width: 1281px) and (max-width: 1549px) {

  .my-image{
    height: 90px !important;
    width: 90px !important;
    margin-right :  10px !important;
    margin-top:43px !important;
    border: 5px solid #147b82;
  }
  .my-images{
    height: 90px !important;
    width: 90px !important;
    margin-right : 10px !important;
    margin-top:43px !important;
  }
  .main-card{
    margin-top: 20%;
    justify-content: space-evenly;
  }
}

@media(min-width: 1550px) {

  .my-image {
    height: 90px !important;
    width: 90px !important;
    margin-right: 10px !important;
    margin-top: 20px !important;
    border: 5px solid #147b82;
  }
  .my-images {
    height: 90px !important;
    width: 90px !important;
    margin-right: 10px !important;
    margin-top: 20px !important;
  }
}