// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";


@import "./_metronic/_assets/sass/style.react.scss";


@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";

.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}
//@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

//reports
@import "scss/components/reports";
// carousel
@import "./scss/components/carousel";
@import "./scss/components/result";
@import "scss/components/header-btn";
//widget
@import "scss/components/my-widget";
//user-profile
@import "scss/components/user-profile-line";
//breadcrumbs-custom
@import "scss/components/breadcrumb-custom";
//notification-counter
@import "scss/components/notifications";
//filter-category
@import "scss/components/filer-categories";
//floating effect
@import "scss/components/floating";
//live-camera feature all css
@import "scss/components/livecamera";
//added violation card css
@import "scss/components/dynamic-scrollbar-css";
//disable Image
@import "scss/components/disableImage";
//dateRangepicker
@import "scss/components/dateRangePicker";
//infinite-scrollbar
@import "scss/components/infinite-scroll";
//short-label-name-css
@import "scss/components/violation-labels-name";
//marketplace images frames
@import "scss/components/marketplace_my_img_frame";
//marketplace upload and subscription button
@import "scss/components/marketplace_subscription_upload_btn";
.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}
.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.modal .modal-header .close span {
  display: block !important;
}
.MuiRating-label {
  font-size: 3rem !important;
  color: #fdcc0d !important;
}
.detection-image-full-view {
  width: 62vw;
  height: 62vw;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  margintop: -31vw;
  marginleft: -31vw;
  boxshadow: 0 0 40px 5px rgba(0, 0, 0, 0.3);
  transform: none;
  z-index: 10051;
}
.bounding-box-full__main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.4;
  z-index: 10052;
}
.bounding-box-full {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  //display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  z-index: 10053;

  .bounding-box-full__sub {
    height: 100%;
    display: flex;

    .react-transform-component {
      height: 100%;
      vertical-align: middle;
      display: contents;
    }

    .bounding-box-full__menu {
      background: white;
    }
  }
}
.resultDate:focus {
  border-color: #147b82;
  border-top-color: #147b82;
  border-width: 2px;
  outline: white;
}
.resultDate {
  border: 1.4px solid lightgrey;
  width: 100%;
  padding: 8px;
  color: currentColor;
  border-radius: 4px;
}
.resultDate::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #333333;
  opacity: 1; /* Firefox */
}

.employeeTable {
  min-width: 500px;
}
.main-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 11%;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1575px !important;
  }
}
body {
  overflow-y: auto !important;
}
.input-group-prepend-override {
  margin-right : -8px !important;
}



.box-customer-grid{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  flex-wrap: wrap;
}

.box-customer-widget{
  flex-basis: 13.99% !important;
  flex-grow : 1;
}



@media screen and (max-width:1366px) {
  .box-customer-widget{
    flex-basis: 29.99% !important;
    flex-grow : 1;
    margin-bottom: 10px;
  }
}
@media screen and (max-width:1024px) {
  .box-customer-widget{
    flex-basis: 29.99% !important;
    flex-grow : 1;
    margin-bottom: 10px;
  }
}
@media screen and (max-width:540px) {
  .box-customer-widget{
    flex-basis: 100% !important;
    flex-grow : 1;
    margin-bottom: 10px;
  }
}

.applyButton {
  border-color: #147b82 !important;
  border: 1px solid #147b82 !important;
  background-color: #147b82 !important;
}

.form-control-customer .form-control{
  background-color: #fff !important;
}
.header-style{
  height: 18px !important;
}

.wizard-overFlowView{
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media screen and (max-width:960px) {
  .loadcss {
    margin-top: 9.5px !important;
  }
}
@media screen and (max-width:1199px) {
  .loadcss {
    margin-top: 10px !important;
  }
}
@media screen and (max-width:2000px) {
  .dashboard {
    margin-top: 6px !important;
  }
}
@media screen and (max-width:960px) {
  .dashboard {
    margin-top: 17px !important;
  }
}

@media screen and (max-width:420px) {
  .Small {
    display: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 2500px) {
  .space-left {
   margin-left: -72px !important;
  }
}
@media screen and (max-width:330px) {
  .space-left {
   margin-left: -23px !important;
  }
}

//425
@media screen and (max-width:575px) {
  .displays1 {
    display: none !important;
  }
}
//425
@media screen and (max-width:575px) {
  .content {
    justify-content:  center!important;
  }
}

//425
@media screen and (min-width:575px) {
  .delete_large {
    display: none !important;
  }
}

//@media screen and (max-width:3000px) {
//  .word {
//    word-break: keep-all !important;
//  }
//}
//.space{
//  margin-left: 18px !important;
//}
//@media screen and (max-width:450px) {
//  .space {
//    margin-left: 0px !important;
//  }
//}
//@media screen and (max-width:545px) {
//  .space {
//    margin-left: 0px !important;
//  }
//}

.block-ui-overlay {
  opacity: 1;
}

.videoTop{
  margin-top:  45px;
}

.example.MuiCard-root{
  overflow: unset !important;
}

.modalNewSize{
  max-width: calc(100% - 380px) !important;
}

.css-26l3qy-menu{
  z-index: 99999 !important;
}

.svg-icon.svg-icon-primary:hover svg g [fill] {
  fill: #075256 !important;
}

.mainCard {
  background: white;
  box-shadow: 0 0 6px #00000029;
  border-radius: 5px;
  cursor: pointer;
  //height: 290px !important;
  //max-height: 290px !important;
}

.borderStyle{
  border: 1px solid #e2e2e2
}
.popper-width {
  width: 505px!important;
  max-width: 505px !important;
}

.filter-menu{
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;

  .svg-icon svg g [fill] {
    fill: #147b82 !important;
  }
}


.filter-menu:hover{
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
  background:  #147b82;
  color: #fff;

  .svg-icon svg g [fill] {
    fill: #fff !important;
  }
}

.filter-menu-onclick{
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
  background:  #147b82;
  color: #fff;

  .svg-icon svg g [fill] {
    fill: #fff !important;
  }
}


.filter-search-scroll{
  overflow-x: hidden !important;
  height: 150px !important;
  overflow-y: auto !important;
}

.filter-search-scroll::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

.filter-search-scroll::-webkit-scrollbar-track{
  background-color: #e2e2e2;
  border-radius: 25px;
  margin: 15px;
  //margin-top: 72px;
}

.filter-search-scroll::-webkit-scrollbar-thumb{
  background-color: #147b82;
  border-radius: 25px;
  width:20px;
}

.filter-radio {
  .MuiTypography-body1 {
    font-size : 14px!important;
    font-weight: 500!important;
  }
}


.checkbox-new {
  z-index: 2;
  left: 2.25rem;
  top: 1.25rem;
  position: absolute;
}

@media screen and (max-width:768px) {
  .checkbox-new {
    z-index: 2;
    left: 4.0rem;
    top: 1.25rem;
    position: absolute;
  }
}

.card-header-custom{
  font-size: 1.5rem;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.334;
}

.block-ui-container {  z-index: 90;}


.modalNewSize {
  max-width: calc(100% - 380px) !important;
}

/* Add this in your CSS file (e.g., styles.css or TopspinPieChart.css) */
.card-fixed-height {
  height: 500px; /* Fixed height for the cards */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
}

.chart-label {
  font-size: 14px;        /* Consistent font size */
  //color: #333333;         /* Default font color (dark gray) */
  font-family: Helvetica, Arial, sans-serif; /* Font family */
  font-weight: normal;    /* Normal font weight */
  text-decoration: none;  /* No underline */
}

.chart-title {
  font-size: 18px;        /* Larger size for titles */
  //color: #147b82;         /* Consistent primary color */
  font-weight: normal;      /* Bold for emphasis */
}

.chart-chip {
  font-size: 14px;        /* Consistent size for chips */
  background: #147b82;    /* Background color */
  color: #fff;            /* Text color */
  border-radius: 6px;     /* Rounded corners */
  padding: 6px 12px;      /* Padding for spacing */
}

.email-wrap{
    width: 220px !important;
    word-break: break-word !important;
}